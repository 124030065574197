import { Injectable, Inject } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { LocalStorageService } from './localStorage.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { IUserToken } from '../models/IUser';

@Injectable({
  providedIn: 'root'
})
export class JwtdecodeService {

  constructor(private localStorageService: LocalStorageService,
    private jwtHelper: JwtHelperService) { }

  isSuperAdmin() {
    const token = this.localStorageService.get('token');
    const decodedToken = jwt_decode(token) as IToken;
    return decodedToken.userRole === 'SuperAdmin';
  }

  isAdmin() {
    const token = this.localStorageService.get('token');
    const decodedToken = jwt_decode(token) as IToken;
    return decodedToken.userRole === 'Admin';
  }

  isUserTypeEqualsUser() {
    const token = this.localStorageService.get('token');
    const decodedToken = jwt_decode(token) as IToken;
    return decodedToken.userRole === 'Member';
  }

  isTokenExpired() {
    const token = this.localStorageService.get('token');

    if (this.jwtHelper.isTokenExpired(token)) {
      return true;
    }
    return false;
  }

  getUserDetails() {
    const token = this.localStorageService.get('token');
    const userAccessToken: any = jwt_decode(token);

    let user: IUserToken = {
      Email: userAccessToken.sub,
      Name: userAccessToken.name,
      Role: userAccessToken.userRole,
      UserId: userAccessToken.UID
    };
    return user;
  }
}

export interface IToken {
  sub: string,
  userRole: string,
  exp: number
}
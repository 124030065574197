import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit, OnDestroy {

  type: any;
  title: any;
  desc: any;
  private sub: Subscription;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.type = this.route.snapshot.paramMap.get('type');

    this.sub = this.route.data.subscribe(param => {
      if (param.type) {
        this.type = param.type;
      }
      if (param.title) {
        this.title = param.title;
      }
      if (param.desc) {
        this.desc = param.desc
      }
    });

    switch (this.type) {
      case '404':
        if (!this.title) {
          this.title = 'Pagina no encontrada'
        }
        if (!this.desc) {
          this.desc = 'Oopps!! La pagina que buscas no existe.'
        }
        break;
      case '500':
        if (!this.title) {
          this.title = 'Error interno del servidor'
        }
        if (!this.desc) {
          this.desc = 'Oopps!! Ocurrió un error. Por favor intenta mas tarde.'
        }
        break;
      default:
        this.type = 'Ooops..';
        if (!this.title) {
          this.title = 'Algo salio mal';
        }
        if (!this.desc) {
          this.desc = 'Parece que sucedió un error.<br>' + 'Estamos trabajando en ello';
        }
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}

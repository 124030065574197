import { Component, OnInit, ViewChild, ElementRef, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localStorage.service';
import { JwtdecodeService } from 'src/app/services/jwtdecode.service';
import Swal from 'sweetalert2';
import { IProfileDetails } from 'src/app/models/IProfileDetails';
import { UserService } from 'src/app/services/user.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  name: string = '';
  email: string = '';
  role: string = '';
  idUser: number = 0;
  profilePic: any = "";

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private localStorage: LocalStorageService,
    private jwtDecode: JwtdecodeService,
    private userService: UserService,
    private _sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    const user = this.jwtDecode.getUserDetails();
    if (user) {
      this.name = user.Name;
      this.email = user.Email;
      this.role = user.Role;
      this.idUser = parseInt(user.UserId);
      this.getProfileInfo(this.idUser);
    }
    this.profilePic = "../../../../assets/images/default-profile.png"
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e: Event) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout(e: Event) {
    e.preventDefault();
    this.localStorage.clear();
    sessionStorage.clear();
    if (!this.localStorage.get('token')) {
      this.router.navigate(['/auth/login']);
    }
  }

  getProfileInfo(userId: number) {
    this.userService.getProfileDetail(userId).subscribe(
      (result: any) => {

        const profileData = result.responseObject as IProfileDetails;
        if (profileData.profileImg) {
          this.profilePic = this._sanitizer.bypassSecurityTrustResourceUrl(profileData.profileImg);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

}
